import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useRealAuth() {
    const headers = [
        { text: '用户昵称', value: 'nickname' },
        { text: '真实姓名', value: 'real_name' },
        { text: '身份证号', value: 'id_card' },
        { text: '性别', value: 'gender' },
        { text: '手机号', value: 'mobile' },
        { text: '认证方式', value: 'auth_type' },
        { text: '状态', value: 'auth_state' },
        { text: '审核失败原因', value: 'failed_reason' },
        { text: '申请时间', value: 'create_at' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        key: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: {
            open: false,
            msg: ''
        },
        lodading: false,
        genders: {
            1: "男",
            2: "女",
        },
        authTypes: {
            MAN_USER_MOBILE: '人工认证'
        },
        authStatus: {
            1: "成功",
            2: "失败",
            3: "待审核",
            4: "待短信验证",
        },
        authStatusColor: {
            1: "success",
            2: "error",
            3: "primary",
            4: "info",
        },
        dialogRefuse: {
            open: false,
            item: { id: '', nickname: '', real_name: '', failed_reason: '' }
        }
    })
    const fetchRealAuthList = () => {
        table.value.loading = true
        store.dispatch('app-user-real-auth-list/fetchRealAuthList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const applyAccept = (item) => {
        table.value.loading = true
        store.dispatch('app-user-real-auth-list/fetchRealAuthAccept', item).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            fetchRealAuthList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const applyRefuse = () => {
        table.value.loading = true
        store.dispatch('app-user-real-auth-list/fetchRealAuthRefuse', table.value.dialogRefuse.item).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snak = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogRefuse.open = false
            fetchRealAuthList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchRealAuthList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        fetchRealAuthList, applyAccept, applyRefuse
    }
}
